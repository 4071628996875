export const moduleSlug = "phygital";
export const moduleAuth = "Phygital";

export const children = [
  {
    path: "",
    component: () => import("./pages/PhygitalLinks.vue"),
    meta: {
      title: "Links",
      linkTitle: "Links",
      linkIcon: "list",
      showInNav: true,
      permission: "Phygital.Read",
    },
  },
  {
    path: "create",
    component: () => import("./pages/PhygitalLinkCreate.vue"),
    meta: {
      title: "Generate Phygital Link",
      linkTitle: "Generate Phygital Link",
      linkIcon: "list",
      showInNav: false,
      permission: "Phygital.Create",
    },
  },
  {
    path: "edit/:id",
    component: () => import("./pages/PhygitalLinkCreate.vue"),
    meta: {
      title: "Generate Phygital Link",
      linkTitle: "Generate Phygital Link",
      linkIcon: "list",
      showInNav: false,
      permission: "Phygital.Create",
    },
  },
];
